import {type HgBlogNavbarProps, type NavLink} from '../types'
import {useBlogNavbarContext} from './HgBlogNavbarProvider'
import {cn} from '~/design-system/utils'
import {
  HgButtonLink,
  HgMediaCaption,
  HgTextLink,
} from '~/design-system/hg/components'
import mobileNavVariants from '../../HgNavbar/_variants/mobileNavVariants'
import {RemoveScroll} from 'react-remove-scroll'
import {LinkBox, LinkOverlay} from '~/design-system/foundations'
import CTAButtons from '../../HgNavbar/_components/CTAButtons'

function MobileNavLinkList({
  title,
  links,
  classNames,
}: {
  title: string
  links: NavLink[]
  classNames?: {
    list?: string
    container?: string
  }
}) {
  const {
    state: {navOpen},
  } = useBlogNavbarContext()
  return (
    <div className={classNames?.container}>
      <span className="text-text-subdued arcadia-ui-2">{title}</span>
      <ul className={cn('space-y-s2 pt-s5', classNames?.list)}>
        {links.map(link => (
          <li key={link.name}>
            <HgTextLink
              tabIndex={navOpen ? 0 : -1}
              variant="revealUnderline"
              href={link.href}
              className="flex pb-4 arcadia-ui-1"
            >
              {link.name}
            </HgTextLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

function BlogNavbarTopics({
  featuredTopics,
  topicLinks,
}: Pick<HgBlogNavbarProps, 'topicLinks' | 'featuredTopics'>) {
  const {
    state: {navOpen},
  } = useBlogNavbarContext()
  return (
    <>
      <div className="hidden gap-s5 pr-s4 xl:grid">
        <MobileNavLinkList
          title={topicLinks.title}
          links={topicLinks.topics}
          classNames={{list: 'md:columns-2'}}
        />
        <HgButtonLink
          variant="tonal"
          size="medium"
          tabIndex={navOpen ? 0 : -1}
          {...topicLinks.buttonLinkProps}
        />
      </div>
      <div className="my-s6 grid gap-s4 border-y border-border-frosted py-s6 md:grid-cols-2 xl:order-2 xl:my-0 xl:border-y-0 xl:border-l xl:py-s5 xl:pl-s4">
        {featuredTopics.map(topic => (
          <LinkBox key={topic.name}>
            <HgMediaCaption
              asset={topic.asset}
              aspectRatioProps={{
                aspectRatio: '16:9',
                borderRadius: '0px',
              }}
              captionProps={{
                title: (
                  <HgTextLink
                    variant="revealUnderline"
                    href={topic.href}
                    tabIndex={navOpen ? 0 : -1}
                  >
                    {topic.name}
                  </HgTextLink>
                ),
                body: topic.subheading,
              }}
            />
            <LinkOverlay
              data-analytics-event-name="blog-topic"
              href={topic.href}
              aria-hidden
              tabIndex={-1}
            />
          </LinkBox>
        ))}
      </div>
      {/* Duplicate list to maintain tab order */}
      <div className="grid gap-s5 xl:hidden">
        <MobileNavLinkList
          title={topicLinks.title}
          links={topicLinks.topics}
          classNames={{list: 'md:columns-2'}}
        />
        <HgButtonLink
          variant="tonal"
          size="medium"
          tabIndex={navOpen ? 0 : -1}
          {...topicLinks.buttonLinkProps}
        />
      </div>
    </>
  )
}

export default function HgBlogNavbarMenu({
  categoryLinks,
  topicLinks,
  featuredTopics,
  primaryButtonProps,
}: HgBlogNavbarProps) {
  const {
    state: {navOpen, isResizing},
  } = useBlogNavbarContext()
  const {root} = mobileNavVariants({
    navOpen,
    disableAnimation: isResizing,
  })
  return (
    <div
      // 2px to account for border
      className={root({
        className:
          'top-[calc(var(--subnav-height)-2px)] bg-surface-elevated pt-0 xl:h-auto xl:w-screen xl:translate-x-0 xl:pt-0 xl:transition-[clip-path] xl:duration-300 xl:clip-path-collapsed-up xl:aria-[expanded=false]:ease-translate-out xl:aria-[expanded=true]:ease-translate-in xl:aria-[expanded=true]:clip-path-expanded-down',
      })}
      aria-hidden={!navOpen}
      aria-expanded={navOpen}
      id="blog-nav"
    >
      <RemoveScroll enabled={navOpen}>
        <div className="max-h-blog-screen-without-navbars w-full overflow-y-auto border-t border-border-frosted p-s5 xl:grid xl:grid-cols-2">
          <MobileNavLinkList
            title={categoryLinks.title}
            links={categoryLinks.categories}
            classNames={{container: 'xl:hidden'}}
          />
          <BlogNavbarTopics
            featuredTopics={featuredTopics}
            topicLinks={topicLinks}
          />
          <div className="mt-s6 flex border-t border-border-frosted pt-s6 md:hidden">
            <CTAButtons
              primaryButtonProps={primaryButtonProps}
              tabIndex={navOpen ? 0 : -1}
            />
          </div>
        </div>
      </RemoveScroll>
    </div>
  )
}
