import HgBlogSearchInput from '~/design-system/hg/components/HgBlogSearchInput'
import {useBlogNavbarContext} from './HgBlogNavbarProvider'
import {internalLinks} from '~/routing/internal-links'
import useQueryStringGenerator from '~/design-system/hooks/useQueryStringGenerator'
import {useEffect, useId} from 'react'
import {useRouter} from 'next/navigation'
import {RemoveScroll} from 'react-remove-scroll'

export default function HgBlogNavSearch() {
  const {
    focusSearch,
    state: {searchOpen, searchValue, searchInputRef, isSearchPage},
    dispatch,
  } = useBlogNavbarContext()
  const queryStringGenerator = useQueryStringGenerator()
  const inputId = useId()
  const router = useRouter()

  useEffect(() => {
    const abortController = new AbortController()

    const hotkeyHandler = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'k') {
        dispatch({searchOpen: true})
        focusSearch()
      }
      if (e.key === 'Escape') {
        dispatch({searchOpen: false})
      }
    }

    window.addEventListener('keydown', hotkeyHandler, {
      signal: abortController.signal,
    })

    return () => {
      abortController.abort()
    }
  }, [dispatch, focusSearch])

  return (
    <div
      className="clip-path-expandedDown absolute left-0 top-full w-full border-y border-border-frosted bg-surface-elevated px-s5 pb-s6 pt-s9 transition-[clip-path] duration-300 ease-translate-out clip-path-collapsed-up aria-expanded:ease-translate-in aria-expanded:clip-path-expanded-down"
      aria-hidden={!searchOpen}
      aria-expanded={searchOpen}
    >
      <RemoveScroll enabled={searchOpen && !isSearchPage}>
        <HgBlogSearchInput
          // TODO: Explore page transitions
          buttonLinkProps={{
            'href': queryStringGenerator(
              {query: searchValue.trim()},
              internalLinks.blogIndex
            ),
            'variant': 'text',
            'contentType': 'icon',
            'iconProps': {iconType: 'search'},
            'size': 'xl',
            'aria-label': 'Search',
          }}
          id={inputId}
          ref={searchInputRef}
          placeholder="Search"
          value={searchValue}
          onChange={e => {
            dispatch({searchValue: e.target.value})
          }}
          onClear={() => {
            dispatch({searchValue: ''})
            focusSearch()
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              router.push(
                queryStringGenerator(
                  {query: searchValue.trim()},
                  internalLinks.blogIndex
                )
              )
            }
          }}
        />
      </RemoveScroll>
    </div>
  )
}
