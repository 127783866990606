'use client'
import {createContext, use, useEffect, useReducer, useRef, useState} from 'react'
import {tv} from 'tailwind-variants'
import {cn} from '~/design-system/utils'
import {usePathname} from 'next/navigation' // Add this import for accessing the current path
import {internalLinks} from '~/routing/internal-links'

type BlogNavbarProviderState = {
  searchOpen: boolean
  navOpen: boolean
  searchValue: string
  searchInputRef: React.Ref<HTMLInputElement>
  isResizing: boolean
  isSearchPage: boolean
}

type BlogNavbarProviderContext = {
  toggleSearch: () => void
  toggleNav: () => void
  focusSearch: () => void
  state: BlogNavbarProviderState
  dispatch: React.Dispatch<Partial<BlogNavbarProviderState>>
}

const BlogNavbarContext = createContext<BlogNavbarProviderContext | null>(null)

const blogNavbarVariants = tv({
  base: 'transition-opacity duration-300',
  variants: {
    isVisible: {
      true: 'pointer-events-auto opacity-100',
      false: 'pointer-events-none opacity-0',
    },
  },
})

function blogNavbarReducer(
  state: BlogNavbarProviderState,
  action: Partial<BlogNavbarProviderState>
) {
  return {...state, ...action}
}

export default function HgBlogNavbarProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const pathname = usePathname() // Get the current path
  const timeoutRef = useRef<NodeJS.Timeout>(undefined)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const [state, dispatch] = useReducer(blogNavbarReducer, {
    searchOpen: false,
    navOpen: false,
    isResizing: false,
    searchValue: '',
    searchInputRef,
    isSearchPage: pathname === internalLinks.blogIndex,
  })
  const [isVisible, setIsVisible] = useState(false)

  const {searchOpen, navOpen, isSearchPage} = state

  const toggleSearch = () => {
    // If we're on the search page, always close the search overlay since we have a dedicated search input
    if (isSearchPage) {
      dispatch({searchOpen: false, navOpen: false})
      return
    }

    // normal toggle behavior for non-search pages
    dispatch({searchOpen: !searchOpen, navOpen: false})

    if (!searchOpen) {
      focusSearch()
    }
  }
  const toggleNav = () => {
    dispatch({navOpen: !navOpen, searchOpen: false})
  }

  const focusSearch = () => {
    if (!searchInputRef.current) return
    searchInputRef.current.focus()
    searchInputRef.current.select()
  }

  // Update isSearchPage if pathname changes
  useEffect(() => {
    dispatch({isSearchPage: pathname === internalLinks.blogIndex})
  }, [pathname])

  /*
   * Prevent transition animation from running when resizing the window.
   * Necessary because the menu drawer opens from the right on tablet, but from the left on mobile
   * Without this, the drawer would animate across the screen when resizing the window and crossing breakpoints
   */
  useEffect(() => {
    const abortController = new AbortController()

    window.addEventListener(
      'resize',
      () => {
        clearTimeout(timeoutRef.current)
        dispatch({isResizing: true})
        timeoutRef.current = setTimeout(() => {
          dispatch({isResizing: false})
        }, 100)
      },
      {
        signal: abortController.signal,
      }
    )

    return () => {
      abortController.abort()
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    function handleArticleHeroIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(false)
        } else {
          setIsVisible(true)
        }
      })
    }
    const hiddenSections = document.querySelectorAll('[data-nav-hidden=true]')
    if (!hiddenSections.length) {
      setIsVisible(true)
      return
    }
    const observer = new IntersectionObserver(handleArticleHeroIntersection, {
      threshold: 0,
      rootMargin: '-72px',
    })
    hiddenSections.forEach(section => {
      observer.observe(section)
    })
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const navbars = document.querySelectorAll(
      '[id*=navbar] [class*=bg-background-default]'
    )
    const shouldChangeBackground = searchOpen || navOpen
    navbars.forEach(navbar => {
      navbar.classList.toggle('bg-surface-elevated', shouldChangeBackground)
    })
  }, [searchOpen, navOpen])

  return (
    <BlogNavbarContext.Provider
      value={{
        toggleSearch,
        toggleNav,
        focusSearch,
        state,
        dispatch,
      }}
    >
      <div
        className={cn(
          'pointer-events-none absolute inset-0 z-[5] mt-subnav-height h-blog-screen-without-navbars w-screen bg-surface-frosted-active opacity-0 transition-opacity duration-300',
          (navOpen || searchOpen) && 'pointer-events-auto opacity-100'
        )}
        onClick={() => {
          dispatch({navOpen: false, searchOpen: false})
        }}
        aria-hidden
      />
      <div className={blogNavbarVariants({isVisible})}>{children}</div>
    </BlogNavbarContext.Provider>
  )
}

export function useBlogNavbarContext() {
  const context = use(BlogNavbarContext)
  if (!context) {
    throw new Error('useBlogNavbarContext must be used within a BlogNavbarProvider')
  }
  return context
}
