import {tv} from 'tailwind-variants'

const mobileNavVariants = tv({
  slots: {
    root: 'absolute left-0 top-0 h-screen w-full -translate-x-full bg-surface-elevated transition-transform duration-300 md:left-[unset] md:right-0 md:w-[544px] md:translate-x-full ',
    content:
      'absolute top-0 z-[1] min-h-screen w-full -translate-x-full bg-surface-elevated data-[state=closed]:animate-animateTranslateReverse data-[state=open]:animate-animateTranslate md:translate-x-full',
    bar: 'bg-background-transparent relative z-navbar-z-index m-0 flex w-screen flex-row items-center justify-between px-s5 py-16 transition-colors duration-300',
  },
  variants: {
    navOpen: {
      true: {
        root: 'translate-x-0 md:translate-x-0',
      },
    },
    isScrolled: {
      true: {
        bar: 'bg-background-default backdrop-blur-[10px]',
      },
    },
    isBlog: {
      true: {
        bar: 'min-subnav-height h-subnav-height',
      },
      false: {
        bar: 'h-navbar-height min-h-navbar-height',
      },
    },
    showDropShadow: {
      true: {
        bar: '',
      },
    },
    transitionDirection: {
      enter: {
        root: 'ease-translate-in',
      },
      exit: {
        root: 'ease-translate-out',
      },
    },
    disableAnimation: {
      true: {
        root: 'transition-none',
        content: 'md:translate-x-full',
      },
    },
  },
  compoundVariants: [
    {
      isScrolled: true,
      showDropShadow: true,
      className: {
        bar: 'shadow-[0px_0px_6px_0px_rgba(86,86,118,0.10)]',
      },
    },
  ],
  defaultVariants: {
    isBlog: false,
  },
})

export default mobileNavVariants
